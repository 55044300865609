'use client';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

const REWARDFUL_API_KEY = 'a1c040';
const REWARDFUL_SCRIPT_URL = 'https://r.wdfl.co/rw.js';
const PYLON_APP_ID = process.env.NEXT_PUBLIC_PYLON_APP_ID;

export function Scripts(): ReactNode {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return <></>;
  }

  return (
    <>
      <Script src={REWARDFUL_SCRIPT_URL} data-rewardful={REWARDFUL_API_KEY} />
      <Script id="rewardful-queue" strategy="beforeInteractive">
        {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
      </Script>
      <Script id="pylon-widget">
        {`(function(){var e=window;var t=document;var n=function(){n.e(arguments)};n.q=[];n.e=function(e){n.q.push(e)};e.Pylon=n;var r=function(){var e=t.createElement("script");e.setAttribute("type","text/javascript");e.setAttribute("async","true");e.setAttribute("src","https://widget.usepylon.com/widget/${PYLON_APP_ID}");var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};if(t.readyState==="complete"){r()}else if(e.addEventListener){e.addEventListener("load",r,false)}})();`}
      </Script>
    </>
  );
}
